<template>
  <div class="returnees">
    <Header />
    <div class="rated-content">
      <div class="rated-title">「艾尔伴家」为社区提供智慧解决方案</div>
      <div class="rated-subtitle">
        <div>
          <span>文章作者：</span>
          <span>小艾</span>
        </div>
        <div>
          <span>发布时间：</span>
          <span>2021-09-01 14:27</span>
        </div>
      </div>
      <div class="rated-bg">
        <img src="../../../static/images/media-bg.png" alt="" style="width:100%;height:100%;">
      </div>
      <p class="rated-text">
        今年11月18日，人贩子“梅姨”的画像刷屏了朋友圈。
在画像中，“寻找梅姨”“涉及9起拐卖儿童案件”“梅姨至今仍未落网，可能还有更多的小孩子落网”的句子触痛了家长们敏感的神经。
事件很快就被反转。朋友圈对画像进行疯传的几小时内，公安部儿童失踪信息紧急发布平台官方微博便发布消息称，网络上流传的广东增城被拐9名儿童案件嫌疑人“梅姨”的第二张画像非官方公布信息，梅姨是否存在，长相如何，暂无其他证据印证。
纵不论画像的真假，此事件背后折射出的是人们对社会安全的担心。无论是儿童拐卖，还是入室行窃，亦或是司空见惯的牛皮癣广告，无一不是社会治理里重要的部分，而对于人们经常活动的小区来讲，则更是重中之重。
位于两江数字经济产业园的重庆市艾尔伴家科技有限公司（以下简称“艾尔伴家”），专注于为用户提供智慧社区的解决方案，并帮助社区进行智能化防控。“住宅小区是社会治安防控体系的最小单元，只有小区的小平安才能积累全社会的大平安。”艾尔伴家董事长杨欣玥告诉36氪重庆，打造智慧小区是在传统人防、物防、技防的基础上，通过应用先进的物联网技术，实现小区24小时智能管控，为侦查破案提供数据支撑，同时也让智慧服务融入居民的日常生活，为社区构筑一道“智能安全围墙”。
具体来说，艾尔伴家在小区的出入口、单元楼栋进行智能门禁的安装。该智能门禁支持刷脸和刷卡，方便小区住户出入。“在技术上面我们也进行了创新，不再像传统的那样需要布线，且把室内分机给淘汰了，只需要在手机上装一个APP即可。” 通过APP，住户不管在任何地方都可以远程开门，也可以查看单元门的情况，以及访客的留言、呼叫信息。 
以门为入口，小区的住户需要在社区或者物管进行信息的登记，比如人脸、身份信息和是租赁户还是常住人口等，这样就把小区的流动人口跟常住人口区分，于是产生了一个社区人员的基础数据库。再者，门禁系统不间断的抓拍，就形成了人员流动的底库，这为公安系统提供了侦查线索。门禁机、刷脸机，加上社会的安保力量，形成了一套社区的安防体系。以艾尔伴家服务的花园新村举例，在改造之前，该小区是社会整治A类挂牌，周边人员流动复杂。从今年5月份开始试点到现在，受益于整个智能体系的搭建，花园新村成功实现了摘牌。
社区安防是个长期的生意。除了在技术上需要不断迭代，更好地处理诸如在多角度、多姿态、黑白照片、跨年龄、戴口罩、光线等特殊情况下的识别，还考验企业在服务上是否具有精细化的持续服务能力。为此，艾尔伴家提出了“两小时内响应，4小时到现场”的口号。当出现异常情况时，住户在APP上发出诉求，运营人员便会立刻赶来巡检。
除了巡检这样事后的服务，艾尔伴家也在努力将问题解决方案前置。“在进行小区改造前，我们会把方案拿到消防局去看符不符合消防规定和交通规定。很多老旧社区的大门是唯一的逃生通道，因此门是朝外开，还是朝内开，断电了怎么办，都是要慎重考虑并给出解决方案的。”杨欣玥表示。
与传统单纯售卖智能锁、智能门禁系统的公司不同，艾尔伴家作为解决方案提供商，盈利的模式也会有所不同。在杨欣玥看来，公共通道的流量具有足够的商业价值。其考验的是对用户深度与持续的服务：当用户养成行为习惯后，不断积累了足够的行为数据，艾尔伴家可针对此进行深度的开发。比如针对一些养老社区，艾尔伴家可在APP上对接医疗资源，覆盖社区500米到1000米的地方。还比如，在单元楼的门禁机的电子屏幕上，除了目前提供的公益广告外，艾尔伴家还可提供商业广告，从而实现一定的营收。
作为海归创业项目，艾尔伴家的核心团队由哥伦比亚大学、美国西北大学、美国宾夕法尼亚州立大学的海外留学归国人员和国内智慧城市领域的知名专家组成。作为2016年重庆国际人才创新创业洽谈会优秀项目代表签约入驻重庆，艾尔伴家入选了重庆市留学人员回国创业创新支持计划。
目前，艾尔伴家的项目在重庆、广东、四川、陕西、新疆等多个省市成功落地。
针对未来发展，杨欣玥表示艾尔伴家将会不断迭代产品，并提高后续的服务能力。
      </p>
      
    </div>
    <div class="footer" @click="jumpTo">下一篇：行业动态内容>></div>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  export default {
    name: 'Returnees',
    components: { Header },
    methods: {
      jumpTo() {
        this.$router.push('/safety')
      }
    }
  }
</script>

<style scoped>
.rated-content{
  margin-top: 1.16rem;
  padding: 0.59rem 0.4rem 0.65rem 0.4rem;
  box-sizing: border-box;
}
.rated-title{
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-bottom: 0.65rem;
}
.rated-subtitle{
  display: flex;
  justify-content: space-between;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}
.rated-subtitle > div{
  flex: 1;
}
.rated-bg{
  width: 100%;
  height: 5.73rem;
  margin-top: 0.65rem;
  margin-bottom: 0.84rem;
}
.rated-text{
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 0.64rem;
  text-indent: 2em;
  margin-bottom: 0.5rem;
}
.footer{
  height: 1rem;
  position: fixed;
  bottom: 0rem;
  right: 0.43rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  background: #fff;
  width: 100%;
  text-align: right;
  line-height: 1rem;
}
</style>